import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/challenge-8/",
  "date": "2020-09-11T00:00:00.000Z",
  "title": "Access granted",
  "blurb": "My challenges and victories whilst attempting challenge #8",
  "tags": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Accessibility is boring with a capital B-O-R-I-N-G. I suppose in an ideal world, we wouldn't have to deal with the issue of accessibility and everything we as developers create would automatically adhere to the a11y standards of accessibility. But the fact of the matter is we exist in this not so ideal world with options and choices. Who wants to decide between an iPhone or another mobile device? EW!`}</p>
    <p className="emoji-separator" role="image" aria-label="mobile phone">
  📱
    </p>
    <p>{`I'm totally offending people who are seriously either impaired and physically disabled. My only impairment like the stereotypical nerd is my vision. It's not so great but corrective lenses help alleviate this ailment and make life manageable. But there exist people who don't have this luxury. May it be they are so visually impaired that corrective lenses don't fully correct vision. Or they require additional devices to help navigate the web. I once recall watching a video where a guy was able to navigate using his tongue. Quite interesting these feats in technology have come to make information more accessible.`}</p>
    <p className="emoji-separator" role="image" aria-label="glasses">
  👓
    </p>
    <p>{`This task has been more of a research task–an introduction to the world of accessibility. From what I've gathered from various pages and resources (links below), I've noted a few commonalities. First, form fields should be clear, logical and organised. Another key point: items should be able to be accessed via keyboard only (i.e. tabbable). This applies to all links and interactive features on the page, not just form fields. And fields should be appropriately labelled with `}<span className="code">{`aria`}</span>{` labels or the `}<span className="code">{`<`}{`label`}{`>`}</span>{` element. With images, the `}<span className="code">{`alt`}</span>{` text is required. There's more to that but if accessibility had to be contained within a nutshell, those are the essential elements.`}</p>
    <p className="emoji-separator" role="image" aria-label="peanuts">
  🥜
    </p>
    <p>{`So, checking off my blog with the aforementioned points, we have a site with the following qualities:`}</p>
    <ul className="checklist">
  <li>All fields are clear, logical and organised</li>
  <li>All fields have labels</li>
  <li>All clickable elements are tabbable</li>
  <li>All images contain alt texts</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      